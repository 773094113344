import React, {Component} from 'react';
import {connect} from 'react-redux'
import {Nav, NavItem, NavLink, TabContent, TabPane} from "reactstrap";
import NotFound from "../NotFound";
import Loading from "../Loading";
import {guiSet, addUrlQuery} from "../../actions/uiActions";
import DzeramaUdensSatecesBaseinsInfo from "./DzeramaUdensSatecesBaseinsInfo";
import { dzeramaUdensSatecesBaseinsActions } from '../../actions/dzeramaUdensSatecesBaseiniActions';
import SplitterLayout from 'react-splitter-layout';

import IzraktenisMap from '../karte/IzraktenisMap';
import * as maputils from '../karte/maputils';

const getTabCfgs = () => {
  return {
    pamatinfo: {
      title: 'Pamatinformācija',
      comp: DzeramaUdensSatecesBaseinsInfo
    },
    visparigais_raksturojums: {
      title: 'Vispārīgais raksturojums',
      // comp: TODO:
    },
    aizsargjoslas: {
      title: 'Aizsargjoslas',
      // comp: TODO:
    },
    monitorings: {
      title: 'Monitorings',
      // comp: TODO:
    },
    apdraudejumi: {
      title: 'Apdraudējumi',
      // comp: TODO:
    },
    riska_novertejums: {
      title: 'Riska novērtējums',
      // comp: TODO:
    },
    pasakumi: {
      title: 'Pasākumi',
      // comp: TODO:
    },
    kopsavilkums: {
      title: 'Kopsavilkums',
      // comp: TODO:
    },
    // TODO: Pēc nepieciešamības pievienot papildus cilnes
  }
}

class BaseinsTabs extends Component {

  renderTabNavLinks = (activeTab, isNew) => {
    return Object.keys(this.tabCfgs).map(k => (
      <NavItem key={k}>
        <NavLink className={k === activeTab ? 'active' : ''} disabled={isNew && this.tabCfgs[k].disableWhenNew}>
          {this.tabCfgs[k].title}
        </NavLink>
      </NavItem>
    ))
  }

  renderTabContents = (activeTab, isNew) => {
    return Object.keys(this.tabCfgs).map(k => {
        const Comp = this.tabCfgs[k].comp ? this.tabCfgs[k].comp : NotFound
        return <TabPane key={k} tabId={k}>
          <Comp/>
        </TabPane>
      }
    )
  }

  render() {
    const {activeTab, isNew, getTabCfgsFunc} = this.props
    if (!this.tabCfgs) {
      const f = getTabCfgsFunc ? getTabCfgsFunc : getTabCfgs
      this.tabCfgs = f()
    }
    return <>
      <Nav tabs>
        {this.renderTabNavLinks(activeTab, isNew)}
      </Nav>
      <TabContent activeTab={activeTab}>
        {this.renderTabContents(activeTab, isNew)}
      </TabContent>
    </>
  }
}

export class BaseinsKartina extends Component {

  mapPaneSizeChange = (w) => {
    this.props.guiSet('dzerama_udens_sateces_baseini_saraksts_karte_width', w);
  }

  mapPaneSizeChangeHor = (h) => {
    this.props.guiSet('dzerama_udens_sateces_baseini_saraksts_karte_height', h);
  }

  handleOnSelect = (row, isSelect) => {
    this.props.dzeramaUdensSatecesBaseinsSelected({id: row.id, isSelect})
  }

  render() {
    const {dzeramaUdensSatecesBaseins, gui} = this.props

    const {selectedItem, selectedBaseins} = dzeramaUdensSatecesBaseins

    // loading while we have no dzeramaUdensSatecesBaseins
    if (!selectedItem) {
      return (<Loading/>)
    }

    const vectorLayerCfg = {
        dzeramaUdensSatecesBaseinsUrbumi: {
            title: 'Sateces baseinā esošās dzeramā ūdens ieguves vietas (urbumi)',
            features: selectedItem.udens_ieguves_vietas,
            styles: maputils.urbumiStyles,
            tooltipFunc: (feat) => `${feat.get('urbumi_id')}: ${feat.get('urb_nr_pirmavota') ? feat.get('urb_nr_pirmavota') : ''} ${feat.get('urb_adrese') ? feat.get('urb_adrese') : ''}`,
            selected: selectedBaseins,
            legend: maputils.urbumiLegend,
            featureInfoTitle: (feat) => `Urbums: ${feat.get('urbumi_id')}`,
            featureInfoColor: 'light',
            featureInfoLinkParams: (feat) => ({
                loc: 'loc_urbums',
                extraPayload: { id: feat.id }
            }),
        },
        // dzeramaUdensSatecesBaseinsPazemesUdensObjekti : {
        //   title: 'Sateces baseina pazemes ūdeņu objekti',
        //   features: selectedItem.pazemes_udenu_objekti,
        //   styles: maputils.uoStyles.uoStyle,
        //   tooltipFunc: (feat) => `${feat.get('kods')}`,
        //   selected: this.props.dzeramaUdensSatecesBaseins.selected,
        //   legend: maputils.defaultSinglePolygonLegend,
        //   featureInfoTitle: (feat) => `PŪO: ${feat.get('kods')}`,
        //   featureInfoColor: 'light',
        //   featureInfoLinkParams: (feat) => ({
        //       loc: 'loc_pazemes_udens_objekts',
        //       extraPayload: { kods: feat.kods }
        //   }),
        // }
    }

    const {
      query, getTabCfgsFunc, addUrlQuery
    } = this.props

    const isNew = !!(selectedItem && (!selectedItem.id || selectedItem.id === 'jauns'))
    const activeTab = query && query.tab && !isNew ? query.tab : 'pamatinfo'

    const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
    const largeScreen = vw > 900

    return (<>
        <SplitterLayout
          key={largeScreen ? 'hor' : 'ver'}
          vertical={!largeScreen}
          customClassName="pane-paddings-hor"
          percentage
          primaryIndex={0}
          secondaryInitialSize={largeScreen ? gui.dzerama_udens_sateces_baseini_saraksts_karte_width : gui.dzerama_udens_sateces_baseini_saraksts_karte_height}
          onSecondaryPaneSizeChange={largeScreen ? this.mapPaneSizeChange : this.mapPaneSizeChangeHor}
        >
          <div className="leftside">
            <BaseinsTabs activeTab={activeTab} isNew={isNew} getTabCfgsFunc={getTabCfgsFunc} addUrlQuery={addUrlQuery}/>
          </div>

          <div className="rightside">
              <div className="karte-wrapper">
                  <IzraktenisMap mapId="dzerama_udens_sateces_baseins_kartina" vectorLayerCfgs={vectorLayerCfg} />
              </div>
          </div>
        </SplitterLayout>
      </>

    )
  }
}

const mapStateToProps = ({dzeramaUdensSatecesBaseins, gui, location}) => ({ dzeramaUdensSatecesBaseins, gui, query: location.query })

const DzeramaUdensSatecesBaseinsKartina = connect(mapStateToProps, {...dzeramaUdensSatecesBaseinsActions, guiSet, addUrlQuery})(BaseinsKartina)

export default DzeramaUdensSatecesBaseinsKartina
