import Cookies from 'js-cookie'

import * as constants from './constants'
import { parseISO } from 'date-fns'

import { registerDeferred } from "./ws"
import { addExtraFilterToQuery, getDateCrudParams } from "./actions/helpers";

export const hostize = (uri) => "/services" + uri
const crudPath = '/data'
const exportPath = '/export'
const countPath = '/count'
const remotePath = '/remote'

export const paramsToQuery = (params) => {
    let query = ''
    if (params) {
        query = '?' + Object
            .keys(params)
            .map(k => k + '=' + encodeURIComponent(params[k]))
            .join('&');
    }
    return query
}

export const csrfHeader = () => {
    return {
        'X-CSRF-Token': Cookies.get("CSRF-TOKEN")
    }
}

// export const getAtsAccessToken = () => {
//     const AtsUrl = `${window.location.origin}/services/remote/kadastrs`
//     return Cookies.get("ATS-TOKEN")
// }

const known_datetime_fields = ['datums', 'aktualizacijas_laiks', 'protokola_datums', 'ierobezota_pieejamiba_datums_no',
    'ierobezota_pieejamiba_datums_lidz', 'piesledzies_laiks', 'izsniegts_datums', 'derigs_lidz_datums',
    'anulets_datums', 'sakums_datums', 'izmainu_datums', 'l_izsniegts_datums', 'l_derigs_lidz_datums', 'access_time',
    'lic_izsniegts_datums', 'lic_derigs_lidz_datums', 'lim_izsniegts_datums', 'lim_derigs_lidz_datums', 'izveidots_datums',
    'pabeigts_datums', 'ped_izmainas', 'upload_time', 'dok_derigs_lidz_datums', 'merijuma_datums',
    'datums_no', 'datums_lidz', 'analizes_datums', 'darbibas_sakums', 'darbibas_beigas', 'iesniegsanas_datums', 'lim_anulets_datums',
    'lic_anulets_datums', 'draudu_konst_datums', 'ieraksta_reg_dat', 'piekluves_laiks', 'start_time', 'end_time', 'krajumu_stavoklis_uz_datums',
    'l_anulets_datums', 'la_krajumu_stavoklis_uz_datums', 'lim_apj_krajumu_stavoklis_uz_datums',
    'perioda_sākuma_datums', 'perioda_beigu_datums', 'izveides_laiks', 'izveidošanas_datums', 'labošanas_datums', 'request_time', 'response_time']

const parseJson = (json, fieldKey) => {
    if (json) {
        const fieldValue = fieldKey ? json[fieldKey] : json
        if (Array.isArray(fieldValue)) {
            //iterate deeply
            fieldValue.forEach(el => parseJson(el))
        } else if (typeof fieldValue === 'object' && fieldValue) {
            Object.keys(fieldValue).forEach((k) => {
                parseJson(fieldValue, k)
            })
        } else if (fieldKey) {
            parseDates(fieldKey, json)
        }
    }
}

const parseDates = (key, json) => {
    if (known_datetime_fields.indexOf(key) !== -1 && json[key]) {
        json[key] = parseISO(json[key])
    }
}

const getContent = (response) => {
    const { headers } = response
    const contentType = headers && headers.get("content-type")
    const func = (contentType === 'application/json')
        ? () => response.json()
        : () => response.text()
    return func()
}

const checkDeferred = (url, viewName, opts = {}) => {
    const headers = {
        ...csrfHeader()
    }
    if (deferredViews.indexOf(viewName) !== -1 || opts.deferred) {
        headers['x-deferred'] = true
    }
    return fetch(hostize(url), { headers }).then((resp) => {
        const { ok, headers, status, statusText } = resp
        if (!ok) {
            return getContent(resp).then((msg) => {
                if (msg === 'Request canceled due to too long processing time' && !opts.deferred) {
                    return checkDeferred(url, viewName, { deferred: true })
                } else {
                    const message = msg
                        ? msg
                        : `[${status}] ${statusText}`
                    const error = new Error(message)
                    error.status = status
                    throw error
                }
            })
        }
        else if (ok && headers && headers.get('x-deferred-hash')) {
            return new Promise((resolve, reject) => {
                registerDeferred(headers.get('x-deferred-hash'), resolve, reject)
            })
        } else {
            return checkResponse(resp)
        }
    })
}

export const checkResponse = (response) => {
    const { ok, status, statusText, headers } = response
    const contentType = headers && headers.get("content-type")
    if (ok && contentType === 'application/json') {
        return getContent(response).then((json) => {
            parseJson(json)
            return new Promise(resolve => resolve(json))
        }, err => { return new Promise((resolve, reject) => reject(err)) })
    }
    if (ok)
        return response.text()
    // Error handling
    return getContent(response).then(serverMessage => {
        const message = serverMessage
            ? serverMessage
            : `[${status}] ${statusText}`
        const error = new Error(message)
        error.status = status
        throw error
    })
}

export const saveApi = (viewName, dto) => {
    let method = 'POST'
    let url = `${crudPath}/${viewName}`
    if (dto.id) {
        method = 'PUT'
        url = `${url}/${dto.id}${paramsToQuery(dto.params)}`
    }
    console.log(url)
    const requestOptions = {
        method: method,
        body: JSON.stringify(dto),
        headers: {
            'content-type': 'application/json',
            ...csrfHeader()
        }
    }
    console.log(hostize(url))
    return fetch(hostize(url), requestOptions).then(checkResponse)
}

export const getApi = (viewName, id, params = {}) => {
    const url = `${crudPath}/${viewName}/${id}${paramsToQuery(params)}`
    return checkDeferred(url, viewName)
}

export const newApi = (viewName, params = {}) => {
    const url = `${crudPath}/create/${viewName}${paramsToQuery(params)}`
    return fetch(hostize(url), {
        headers: csrfHeader()
    }).then(checkResponse)
}

export const deleteApi = (viewName, id, params = {}) => {
    const url = `${crudPath}/${viewName}/${id}${paramsToQuery(params)}`
    const requestOptions = {
        method: 'DELETE',
        headers: csrfHeader()
    }
    return fetch(hostize(url), requestOptions).then(checkResponse)
}

// Views that should always be fetched as deferred
const deferredViews = ['udens2_nv_list']

export const listApi = (viewName, opts = {}) => {
    const url = `${crudPath}/${viewName}${paramsToQuery(opts.params)}`
    return checkDeferred(url, viewName);
}

export const exportOds = ({ viewName, mapSize, param }) => {
    const headers = {
        ...csrfHeader()
    }
    headers['x-deferred'] = true
    const pageOpts = formatListOptions({ p: undefined, ps: 1000000, ...addExtraFilterToQuery(getDateCrudParams(param), mapSize) })
    const url = `${exportPath}/${viewName}${paramsToQuery(pageOpts.params)}`
    return new Promise((resolve, reject) => {
        fetch(hostize(url), { headers }).then((resp) => {
            const { ok, headers, status, statusText } = resp
            if (!ok) {
                return getContent(resp).then((msg) => {
                    const message = msg
                        ? msg
                        : `[${status}] ${statusText}`
                    const error = new Error(message)
                    error.status = status
                    throw error
                })
            }
            else if (ok && headers && headers.get('x-deferred-hash')) {
                registerDeferred(headers.get('x-deferred-hash'), resolve, reject, { resolveHash: true })
            } else {
                console.log("Got some shady response", resp)
                // getContent(resp).then(console.log)
                reject()
            }
        }).catch(reject)
    })
}

export const getRemote = (service, params = {}) => {
    const url = `${remotePath}/${service}${paramsToQuery(params)}`
    return fetch(hostize(url)).then(checkResponse)
}

const formatListOptions = (opts, paging = true) => {
    const page = opts.p ? parseInt(opts.p, 10) : 1
    const pageSize = opts.ps ? parseInt(opts.ps, 10) : constants.DEFAULT_SARAKSTS_PAGE_SIZE
    const params = opts.params ? opts.params : {}
    const offset = page * pageSize - pageSize
    const pagingParams = paging ? { offset, limit: pageSize } : {}
    const newParams = {
        ...pagingParams,
        ...params
    }
    if (opts.f && !newParams.hasOwnProperty('filter')) {
        newParams.filter = opts.f
    }
    if (opts.s && !newParams.hasOwnProperty('sort')) {
        newParams.sort = opts.s
    }
    return {
        ...opts,
        params: newParams
    }
}

export const listApiPaginated = (viewName, opts = {}) => {
    const pageOpts = formatListOptions(opts)
    const f = listApi(viewName, pageOpts)
    return f.then((list) => {
        // 100000 is limit for "all records" from the table
        if ((list.length < pageOpts.params.limit && pageOpts.params.offset === 0) || pageOpts.params.limit === 100000) {
            // no need for a separate count-request, less then page size
            return { list, count: list.length }
        }
        const url = `${countPath}/${viewName}${paramsToQuery(pageOpts.params)}`
        return checkDeferred(url, viewName).then((count) => ({ list, count: parseInt(count, 10) }))
    })
}

export const callApi = ({
    uri,
    ...rest
}) => {
    const requestOptions = {
        ...rest
    }
    requestOptions.headers = {
        ...requestOptions.headers,
        ...csrfHeader(),
    }
    return fetch(hostize(uri), requestOptions).then(checkResponse)
}

export const logout = () => {
    window.location = hostize('/api/logout')
}
export const login = (username, password) => {
    const uri = '/api/login'
    var form = new FormData();
    form.append("username", username);
    form.append("password", password);
    return callApi({ uri, method: 'POST', body: form })
}

export const fetchMetadata = () => {
    const uri = '/metadata/*'
    return callApi({ uri })
}

export const fetchUserApi = () => {
    const uri = '/api/'
    return callApi({ uri })
}

export const uploadFile = (file) => {
    const uri = '/upload'
    let form = new FormData()
    form.append('file', file)
    return callApi({ uri, method: 'POST', body: form })
}

export function throttler(delayMs) {
    let timerId, nextAttempt
    return (fun) => {
        clearTimeout(timerId)
        nextAttempt = nextAttempt || (Date.now() + delayMs)
        timerId = setTimeout(() => { fun(); nextAttempt = null }, nextAttempt - Date.now())
    }
}

// export const estimateUploadTime = (fileSizeInBytes) => {
//         const test = speedTest({ maxTime: 5000, acceptGdpr: true, https: true}) // Set the maximum test time in milliseconds
//
//         const data = new Promise((resolve, reject) => {
//             test.on('data', resolve)
//             test.on('error', reject)
//         })
//
//         const uploadSpeedInBytesPerSecond = data.speeds.upload * 1000 // Convert from Mbps to bytes/second
//         const uploadTimeInSeconds = fileSizeInBytes / uploadSpeedInBytesPerSecond
//         console.log(`Upload speed: ${data.speeds.upload} Mbps`)
//         console.log(`Upload Time : ${uploadTimeInSeconds} seconds`)
//         return uploadTimeInSeconds
// }

export const uploadResumable = (file, chunkSize, uuid, progressCallBack) => {
    let controller
    let lastUploadedChunk = 0
    const totalChunks = Math.ceil(file.size / chunkSize);

    const progress = (end, fileSize) => {
        return (end / fileSize * 100).toFixed(2).toString() + '%'
    }

    const uploadChunkAtIndex = (chunkIndex) => {
        const start = chunkIndex * chunkSize;
        const end = Math.min(start + chunkSize, file.size);
        const chunk = file.slice(start, end);

        const uri = `/upload-large?uuid=${uuid}`
        let form = new FormData()
        form.append('file', chunk, file.name)

        const headers = {
            'Content-Range': `bytes ${start}-${end - 1}/${file.size}`,
        }

        return callApi({ uri, method: 'POST', body: form, headers: headers, signal: controller.signal })
            .then((response) => {
                lastUploadedChunk = chunkIndex + 1
                progressCallBack(progress(end, file.size))
                return response
            })
    }

    const chainUpload = (previousPromise, response) => {
        if (lastUploadedChunk === totalChunks - 1) {
            return previousPromise
        } else {
            return previousPromise.then(() => uploadChunkAtIndex(lastUploadedChunk))
        }
    }

    const startUpload = () => {
        if (!controller) {
            controller = new AbortController()
            const remainingChunks = Array.from({ length: totalChunks }, (_, i) => i).slice(lastUploadedChunk)
            return remainingChunks.reduce(chainUpload, Promise.resolve())
        }
    }

    const stopUpload = () => {
        if (controller) {
            controller.abort()
            controller = null
        }
    }

    const resumeUpload = () => {
        if (!controller) {
            controller = new AbortController();
            const remainingChunks = Array.from({ length: totalChunks }, (_, i) => i).slice(lastUploadedChunk)
            return remainingChunks.reduce(chainUpload, Promise.resolve())
        }
    }

    return { startUpload, stopUpload, resumeUpload }
}

export const crudCall = ({ viewName, method, param }) => {
    switch (method) {
        case 'get':
            // specific get without id
            if (Array.isArray(param)) {
                const queryParams = {}
                //all other params we add as query url
                param.filter((p, i) => i > 1).forEach((p, i, arr) => {
                    if (i % 2 === 0) {
                        queryParams[p] = arr[i + 1]
                    }
                })
                if (param[0] === 'id') {
                    return getApi(viewName, param[1], queryParams)
                } else {
                    return getApi(`${viewName}/${param[0]}`, param[1], queryParams)
                }
            } else {
                return getApi(viewName, param)
            }
        case 'save':
            return saveApi(viewName, param)
        case 'delete':
            if (Array.isArray(param)) {
                const queryParams = {}
                //all other params we add as query url
                param.filter((p, i) => i > 0).forEach((p, i, arr) => {
                    if (i % 2 === 0) {
                        queryParams[p] = arr[i + 1]
                    }
                })
                return deleteApi(viewName, param[0], queryParams)
            } else {
                return deleteApi(viewName, param)
            }
        case 'new':
            return newApi(viewName, param)
        case 'list':
            return listApi(viewName, param ? formatListOptions(param, false) : {})
        case 'listPaging':
            return listApiPaginated(viewName, param)
        case 'search':
            return listApi(viewName, { params: param })
        default:
            throw new Error(`not implemented api method ${method}`)
    }
}

export const getAtradneNosaukumsFromNumurs = (numurs) => {
    return getApi('atradne_nosaukums/numurs', numurs).then(atr => atr.nosaukums)
}

export const getAtradneNosaukumsFromId = (id) => {
    return getApi('atradne_nosaukums', id).then(atr => atr.nosaukums)
}

export const getUdensObjNosaukumsForKods = (kods) => {
    return getApi('udens_objekts_nosaukums/kods', kods).then(atr => `${kods} - ${atr.nosaukums}`)
}

export const listParaugsDefaultValues = (urbumi_id) => {
    return listApi("urbuma_merijumu_default", { params: { urbumi_id: urbumi_id } })
}

export const listParaugsCietibaValues = (urbumi_id) => {
    return listApi("urbuma_merijumu_cietiba", { params: { urbumi_id: urbumi_id } })
}

export const getStacijasNosaukumsFromId = (id) => {
    return getApi('stacijas_nosaukums', id).then(res => res.nosaukums)
}

export const getDzeramaUdensSatecesBaseinsNosaukumsFromId = (id) => {
    return getApi('dzerama_udens_sateces_baseins_nosaukums', id).then(res => res.nosaukums)
}