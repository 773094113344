import {checkResponse, hostize, csrfHeader} from "./api";

export const websocketPath = '/services/ws'
const deferredRequests = {}

let ws, wsKeepalive, deferredFallbackCheck;

const startDeferredFallback = () => {
  if (deferredFallbackCheck) {
    clearTimeout(deferredFallbackCheck)
    deferredFallbackCheck = null
  }
  deferredFallbackCheck = setTimeout(() => {
    console.debug("deferredFallbackCheck: Running check on stuck jobs", Object.keys(deferredRequests))
    Object.keys(deferredRequests).forEach((hash) => {
      fetch(hostize(`/deferred/${hash}/status`), {
        headers: csrfHeader()
      }).then((response) => {
        if (response.ok) {
          response.json().then((json) => {
            if (['OK', 'ERR'].indexOf(json.status) !== -1) {
              processDeferred(hash)
            } else {
              console.debug(`deferredFallbackCheck: ${hash} not finished (status: ${json.status}`)
            }
          }).catch((err) => {
            console.error(`deferredFallbackCheck: json() failed for ${hash}`, err)
          })
        } else if (response.status === 404) {
          console.debug(`deferredFallbackCheck: ${hash} not found on server - cleanup browser`)
          const req = deferredRequests[hash]
          delete deferredRequests[hash]
          req.reject({ error: 'Nezināma sistēmas kļūda' })
        }
      }).catch((err) => {
        console.error(`deferredFallbackCheck: Fetch failed for ${hash}`, err)
      })
    })
    startDeferredFallback()
  }, 15 * 1000)
}

const processDeferred = (hash) => {
  const promise = deferredRequests[hash]
  delete deferredRequests[hash]
  if (!promise) {
    return;
  }
  if (promise.opts && promise.opts.resolveHash) {
    promise.resolve(hash)
  } else {
    fetch(hostize(`/deferred/${hash}/result`), {
      headers: csrfHeader()
    }).then((response) => {
      promise.resolve(checkResponse(response))
    }).catch(promise.reject)
  }
}

export const registerDeferred = (hash, resolve, reject, opts = {
  resolveHash: false
}) => {
  deferredRequests[hash] = { resolve, reject, opts }
}

export const startWs = () => {
  stopWs()

  const createWebSocketAfter30Sec = () => {
    setTimeout(() => {
      startWs()
    }, 30 * 1000)
  }

  ws = new WebSocket((window.location.protocol === "https:" ? "wss:" : "ws:") + "//" + window.location.host + websocketPath)
  ws.onmessage = (message) => {
    const response = JSON.parse(message.data)
    if (response.method === 'ad_import_data') {
      window.the_store.dispatch({ type: 'ad_import_data_response', payload: response })
    }
    if (response.method === 'ad_reimport_data') {
      window.the_store.dispatch({ type: 'ad_reimport_data_response', payload: response })
    }
    Object.keys(response).forEach((key) => {
      if (['OK', 'ERR'].indexOf(response[key].status) !== -1) {
        processDeferred(key)
      }
    })
  }

  ws.onerror = () => {
    createWebSocketAfter30Sec()
  }

  wsKeepalive = setInterval(() => {
    if (ws && ws.readyState === WebSocket.OPEN) {
      ws.send("ALIVE")
    } else {
      createWebSocketAfter30Sec()
    }
  }, 45 * 1000)

  startDeferredFallback()
}

export const stopWs = () => {
  if (wsKeepalive) {
    clearInterval(wsKeepalive)
    wsKeepalive = null
  }
  if (deferredFallbackCheck) {
    clearTimeout(deferredFallbackCheck)
    deferredFallbackCheck = false
  }
  if (ws && ws.readyState === WebSocket.OPEN) {
    ws.close()
  }
  ws = null
}
