import * as helpers from './helpers'

const defaultListReducerForAdIeraksti = helpers.createListReducer({listViewName: 'ad_ieraksts_list'})
export const ad_ieraksti = (state = helpers.listGetInitialState(), {type, payload, reqId}) => {
    //overwrite or add custom actions catches here
    return defaultListReducerForAdIeraksti({state, type, payload, reqId})
}

const defaultEditReducerForAdIeraksts = helpers.createEditReducer({editViewName: 'ad_ieraksts_view', actionsName: 'ad_ieraksts'})
export const ad_ieraksts = (state = helpers.editGetInitialState(), {type, payload, reqId}) => {
    //overwrite or add custom actions catches here
    const result = defaultEditReducerForAdIeraksts({ state, type, payload, reqId })

    switch (type) {
      case 'ad_reimport_data_save_success':
      case 'ad_reimport_data_save_failure':
      case 'ad_ieraksts_view_get_success':
        return {
          ...result,
          responseSuccess: null
        };
      case 'ad_reimport_data_response': {
        let responseSuccess = null
        
        if (payload.ieraksts_id === result.selectedItem.id) {
          result.selectedItem.var_veikt_manuālu_ielādi = true
          responseSuccess = payload.success
        }
        return {
          ...result,
          loading: false,
          responseSuccess: responseSuccess
        };
      }
      default:
        return result;
    }
}

const defaultEditReducerForAdDownloadIeraksts = helpers.createEditReducer({editViewName: 'ad_ieraksts_download', actionsName: 'ad_ieraksts_dati'})
export const ad_ieraksts_dati = (state = helpers.editGetInitialState(), {type, payload, reqId}) => {
    //overwrite or add custom actions catches here
    return defaultEditReducerForAdDownloadIeraksts({state, type, payload, reqId})
}
