import Atradne from './atradnes/Atradne';
import AtradnesSaraksts from './atradnes/Saraksts';
import { AtradnesDala } from './atradnes/Atradne';
import VirszemesUdensObjekti from './bais/VirszemesUdensObjekti';
import VirszemesUdensObjekts from './bais/VirszemesUdensObjekts';
import PazemesUdensObjekti from './bais/PazemesUdensObjekti';
import PazemesUdensObjekts from './bais/PazemesUdensObjekts';
import UpjuBaseinuApgabali from './bais/UpjuBaseinuApgabali'
import Klasifikatori from './klasifikatori/Klasifikatori'
import Lietotaji from './lietotaji/Lietotaji'
import NotFound from './NotFound'
import UzzinuSaraksts from './uzzinas/UzzinuSaraksts'
import Uzzina from './uzzinas/Uzzina'
import AdIerakstuSaraksts from './ad/AdIerakstuSaraksts.js'
import AdIeraksts from './ad/AdIeraksts.js'
import AdServisuSaraksts from './ad/AdServisuSaraksts.js'
import AdServiss from './ad/AdServiss.js'
import AdAudits from './ad/AdAudits.js'
import DatnuApmaina from './imports/DatnuApmaina';
import PaseSaraksts from './dokumentacija/PaseSaraksts'
import Pase from './dokumentacija/Pase'
import LimitsSaraksts from './dokumentacija/LimitsSaraksts'
import LicenceSaraksts from './dokumentacija/LicenceSaraksts'
import Limits from './dokumentacija/Limits'
import Licence from './dokumentacija/Licence'
import PielikumuSaraksts from './pielikumi/PielikumuSaraksts'
import KrajumsSaraksts from './krajumi/KrajumsSaraksts'
import Krajums from './krajumi/Krajums'
import Izmaina from './izmainas/Izmaina'
import IzmainaGrouped from './izmainas/IzmainaGrouped'
import Audits from './audit/Audits'
import AuditsPersonasDatu from "./audit/AuditsPersonasDatu";
import AuditsAtradnesAtskaite from './audit/AuditsAtradnesAtskaite'
import IzmainaSaraksts from './izmainas/IzmainaSaraksts'
import Bilance from './izmainas/Bilance'
import PazinojumiSaraksts from './pazinojumi/PazinojumiSaraksts'
import Adresati from './adresati/Adresati'
import PasakumiSaraksts from './pasakumi/Saraksts'
import VirszemesPasakums from './pasakumi/Pasakums'
import PazemesPasakums from './pasakumi/PazemesPasakums';
import PasakumuKlasifikators from './pasakumi/Klasifikators'
import RaditajiEkologija from './raditaji/RaditajiEkologija'
import RaditajiKimiska from "./raditaji/RaditajiKimiska"
import RaditajiQL from "./raditaji/RaditajiQL"
import Nobides from './raditaji/Nobides'
import UrbumiSaraksts from './hidrogeo/UrbumiSaraksts'
import Urbums from './hidrogeo/Urbums'
import UrbumaParaugi from './hidrogeo/UrbumaParaugi'
import UrbumaParaugs from './hidrogeo/UrbumaParaugs'
import UrbumaMerijums from './hidrogeo/UrbumaMerijums'
import UrbumsSastavs from "./hidrogeo/UrbumsSastavs";
import UrbumsNoverotieLimeni from "./hidrogeo/UrbumsNoverotieLimeni";
import UrbumsIeguvesUrbumaLimeni from "./hidrogeo/UrbumsNoverotieLimeni";
import UrbumuAtradnesSaraksts from './hidrogeo/UrbumuAtradnesSaraksts'
import UrbumaAtradne from './hidrogeo/UrbumaAtradne'
import UrbumaIeguve from './hidrogeo/UrbumaIeguve'
import UrbumuIeguvjuSaraksts from './hidrogeo/UrbumuIeguvjuSaraksts'
import BaisImports from "./imports/Imports"
import BaisPazemesImports from "./imports/pazemesImports"
import HidrogeoImports from "./imports/HidrogeoImports"
import GeofondImports from "./geofond/GeofondImports.js";
import UdensNV from "./udens2/UdensNV"
import UdensAI from "./udens2/UdensAI"
import UdensNov from "./udens2/UdensNov"
import UdensParskati from './udens2/Parskati'
import UdensParskats from './udens2/Parskats'
import ParisVkn from "./udens2/ParisVkn"
import SkersprofiliSaraksts from './skersprofili/Saraksts'
import WiseParskats from './wise/WiseParskats'
import WiseVieluParametri from './wise/WiseVieluParametri'
import ParisValidacijaParskats from './paris-validacija/ParisValidacijaParskats'
import ParisValidacijaRezultati from './paris-validacija/ParisValidacijaRezultati'
import IzmainaSarakstsKomersants from './izmainas/IzmainaSarakstsKomersants'
import GeofondParskatsList from "./geofond/GeofondParskatsList"
import GeofondParskatsMap from "./geofond/GeofondParskatsMap"
import GeofondRubrikas from "./geofond/GeofondRubrikas"
import Parskats from "./geofond/Parskats"
import Rubrika from "./geofond/Rubrika"
import { MeteoStacijaList } from "./stacijas/StacijasList"
import StacijaShow from "./stacijas/StacijaShow"
import { TroksnuSaraksts } from "./troksni/Saraksts"
import TroksnuImports from './troksni/Imports'
import VnkSaraksts from './vnk/VnkSaraksts'
import VnkIesniegums from './vnk/VnkIesniegums'
import LimituAtlikumi from './dokumentacija/LimituAtlikumi'
import SistemasPazinojumi from './sistemas-pazinojumi/SistemasPazinojumi.js';
import FonaSlanuAdministresanaSaraksts from './fona-slanu-administresana/FonaSlanuAdministresanaSaraksts.js'
import UdensapgadesSistemasSaraksts from './udensapgades-sistemas/UdensapgadesSistemas.js';
import DzeramaUdensSatecesBaseiniSaraksts from './dzerama-udens-sateces-baseini/Saraksts.js'
import DzeramaUdensSatecesBaseinsKartina from './dzerama-udens-sateces-baseini/DzeramaUdensSatecesBaseinsKartina.js'

export const comps = {
    Atradne, AtradnesSaraksts, AtradnesDala, Klasifikatori, Lietotaji, NotFound, UzzinuSaraksts,
    Uzzina, DatnuApmaina, PaseSaraksts, Pase, LimitsSaraksts, LicenceSaraksts, Limits, Licence, PielikumuSaraksts,
    KrajumsSaraksts, Krajums, Izmaina, IzmainaGrouped, Audits, AuditsPersonasDatu, AuditsAtradnesAtskaite, IzmainaSaraksts, Bilance, PazinojumiSaraksts, Adresati,
    VirszemesUdensObjekti, VirszemesUdensObjekts, PazemesUdensObjekti, PazemesUdensObjekts, VnkSaraksts, VnkIesniegums, UpjuBaseinuApgabali,
    PasakumiSaraksts, VirszemesPasakums, PazemesPasakums, PasakumuKlasifikators,
    RaditajiEkologija, RaditajiKimiska, RaditajiQL, Nobides,
    UrbumiSaraksts, UrbumaParaugi, UrbumaParaugs, Urbums, UrbumaMerijums, UrbumsSastavs, UrbumsNoverotieLimeni, UrbumsIeguvesUrbumaLimeni, UrbumuAtradnesSaraksts, UrbumaAtradne, UrbumaIeguve, UrbumuIeguvjuSaraksts,
    BaisImports, BaisPazemesImports, HidrogeoImports, UdensNV, UdensAI, UdensNov, UdensParskati, UdensParskats, ParisVkn,
    SkersprofiliSaraksts, WiseParskats, WiseVieluParametri,
    ParisValidacijaParskats, ParisValidacijaRezultati, IzmainaSarakstsKomersants,
    GeofondParskatsList, GeofondParskatsMap, Parskats, GeofondRubrikas, GeofondImports, Rubrika,
    MeteoStacijaList, StacijaShow, TroksnuSaraksts, TroksnuImports, LimituAtlikumi, SistemasPazinojumi, FonaSlanuAdministresanaSaraksts,
    AdIerakstuSaraksts, AdIeraksts, AdServisuSaraksts, AdServiss, AdAudits,
    UdensapgadesSistemasSaraksts, DzeramaUdensSatecesBaseiniSaraksts, DzeramaUdensSatecesBaseinsKartina
}
