import {AppSidebarNav} from '@coreui/react';

import classNames from 'classnames';

import React from 'react';
import {NavItem, NavLink as RsNavLink} from 'reactstrap';
import {NavLink} from 'redux-first-router-link'

import {guiSet} from '../../actions/uiActions'

//overrided to use redux-first-router-link
class AppSidebarNavIzraktenis extends AppSidebarNav {

  onNavClick = (e) => {
    window.the_store.dispatch(guiSet('force_reload'))
    this.hideMobile(e)
  }

    navDropdown(item, key) {
      if (item.iconAsComp) {
        const ItemIcon = item.iconAsComp
        const attributes = { ...(item.attributes || {}) }
        const classes = classNames('nav-link', 'nav-dropdown-toggle', item.class, attributes.class)
        delete attributes.class;

        return (
          <li key={key} className={this.activeRoute(item.url, this.props)}>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              className={classes}
              href="#"
              onClick={this.handleClick}
              {...attributes}
            >
              <i className="nav-icon">
                <ItemIcon fillColor="#73818f" />
              </i>
              {item.name}
              {this.navBadge(item.badge)}
            </a>
            <ul className="nav-dropdown-items">
              {this.navList(item.children)}
            </ul>
          </li>
        )
      } else {
        return super.navDropdown(item, key)
      }
    }

    navLink(item, key, classes) {
        const url = item.url ? item.url : '';
        const isExternal = typeof url !== 'object' && this.isExternal(url)
        const itemIcon = <i className={classes.icon} />
        const itemBadge = this.navBadge(item.badge)
        const attributes = item.attributes || {}
        return (
          <NavItem key={key} className={classes.item}>
            { attributes.disabled ?
                <RsNavLink href={""} className={classes.link} {...attributes}>
                  {itemIcon}{item.name}{itemBadge}
                </RsNavLink>
             :
             isExternal ?
                <RsNavLink href={url} className={classes.link} active {...attributes}>
                  {itemIcon}{item.name}{itemBadge}
                </RsNavLink> :
                <NavLink to={url} className={classes.link} activeClassName="active" onClick={this.onNavClick} {...attributes}>
                  {itemIcon}{item.name}{itemBadge}
                </NavLink>
            }
          </NavItem>
        );
      }
}

export default AppSidebarNavIzraktenis
