import React, {Component} from 'react';
import {connect} from 'react-redux'
import Loading from '../Loading';
import GridWithForm from '../grid/GridWithForm'
import {
    getTextFilterCfg
} from '../grid/ColumnHelpers'
import { addUrlQuery, showErrorMsg } from '../../actions/uiActions';
import { udensapgadesSistemasActions, udensapgadesSistemaSelected } from '../../actions/udensapgadesSistemasActions';

const getColumns = () => {
    return [
        {
            dataField: 'nosaukums',
            text: 'Ūdensapgādes sistēmas nosaukums',
            filterCfg: getTextFilterCfg()
        }, {
            dataField: 'operators',
            text: 'Operators',
            filterCfg: getTextFilterCfg()
        }, {
            dataField: 'atlaujas_nr',
            text: 'Atļaujas numurs',
            filterCfg: getTextFilterCfg()
        }
    ]
}

const getFormFieldCfgs = () => {
    return [
        {
            name: 'nosaukums',
            label: 'Ūdensapgādes sistēmas nosaukums',
            required: true
        }, {
            name: 'operators',
            label: 'Operators',
            required: true,
            type: 'text'
        }, {
            name: 'atlaujas_nr',
            label: 'Atļaujas numurs',
            required: true
        }
    ]
}

class UdensapgadesSistemas extends Component {

    render() {

        const { udensapgadesSistemas } = this.props

        if (!udensapgadesSistemas || udensapgadesSistemas.loading) {
            return (<Loading/>)
        }

        if(!this.columns){
            this.columns = getColumns()
        }

        if(!this.formFieldCfgs){
            this.formFieldCfgs = getFormFieldCfgs()
        }

        return (
            <div className="saraksts-wrapper">
                <GridWithForm
                    {...this.props}
                    columns={this.columns}
                    formFieldCfgs={this.formFieldCfgs}
                    storeObj={udensapgadesSistemas}
                    formWidthGuiOption="udensapgades_sistemas_form_width"
                    metadataFormCfg="udensapgades_sistemas_edit"
                    tiesibasLabot="SATECES_BASEINI_LABOT"
                />
            </div>
        )
    }

}


const mapStateToProps = ({udensapgadesSistemas, location}) => ({udensapgadesSistemas, query: location.query})

export default connect(mapStateToProps, {
    selectedAct: udensapgadesSistemaSelected,
    addUrlQuery,
    ...udensapgadesSistemasActions,
    showErrorMsg
})(UdensapgadesSistemas)