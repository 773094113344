import { redirect } from 'redux-first-router'
import { callApi, getDefaultEditActions, addExtraFilterToQuery } from './helpers'

export const loadDzeramaUdensSatecesBaseini = (query) => (dispatch, getState) => {
  const mapSize = getState().gui['dzerama_udens_sateces_baseini_saraksts_karte_size']
  return dispatch(callApi({
      viewName: 'dzerama_udens_sateces_baseins_list',
      crudMethod: 'listPaging',
      crudParam: addExtraFilterToQuery(query, mapSize)
  }))
}

export const dzeramaUdensSatecesBaseinsSelected = (id) => {
   return { type: 'dzerama_udens_sateces_baseins_selected', payload:id }
}

export const dzeramaUdensSatecesBaseinsInMapSelected = (payload) => {
    return { type: 'dzerama_udens_sateces_baseins_selected', payload }
 }

export const dzeramaUdensSatecesBaseinsActions = getDefaultEditActions({
    actionTypeName : 'dzeramaUdensSatecesBaseins',
    stateName: 'dzeramaUdensSatecesBaseins',
    apiViewName: 'dzerama_udens_sateces_baseins_view',
    afterSave: (dispatch, getState, result, savingNew) => {
        dispatch({
            type: 'dzerama_udens_sateces_baseins_clear'
        })
        dispatch(redirect({
            type: 'loc_dzerama_udens_sateces_baseins',
            payload: {
                id: result.id
            }
        }))
    },
})