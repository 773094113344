import * as helpers from './helpers'

const defaultListReducerForDzeramaUdensSatecesBaseini = helpers.createListReducerWithMapClicking({
    listViewName: 'dzerama_udens_sateces_baseins_list', 
    actionsName: 'dzeramaUdensSatecesBaseini',
    mapIdName: 'dzerama_udens_sateces_baseini_saraksts',
    layerIdName: 'dzeramaUdensSatecesBaseini'
})
export const dzeramaUdensSatecesBaseini = (state = helpers.listGetInitialState(), {type, payload, reqId, mapId, layerId}) => {
    return defaultListReducerForDzeramaUdensSatecesBaseini({state, type, payload, reqId, mapId, layerId})
}

const defaultEditReducerForDzeramaUdensSatecesBaseins = helpers.createEditReducer({ editViewName: 'dzerama_udens_sateces_baseins_view', actionsName: 'dzeramaUdensSatecesBaseins' })
export const dzeramaUdensSatecesBaseins = (stateFirst = helpers.editWithGeometryInitialState(), { type, payload, mapId, layerId }) => {
    const state = defaultEditReducerForDzeramaUdensSatecesBaseins({state: stateFirst, type, payload, mapId, layerId})

    switch (type) {
        case 'dzerama_udens_sateces_baseins_selected':
            const selectedBaseins = payload.isSelect ? payload.id : null
            return {
                ...state,
                selectedBaseins
            }
        case 'MAP_clickedOutsideFeature':
            if(mapId === 'dzerama_udens_sateces_baseins_kartina'){
                return {
                    ...state,
                    selectedBaseins: null,
                }
            } else {
                return state
            }
        case 'MAP_clickedFeature':
            if(mapId === 'dzerama_udens_sateces_baseins_kartina'){
                return {
                    ...state,
                    selectedBaseins: payload.id,
                }
            }
            return state
        default:

            return state
    }
}