import React, { Component, useRef } from 'react'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import {
    Col,
    Form as RSForm,
    Input,
    FormGroup,
    Label,
    Button,
    UncontrolledTooltip
} from 'reactstrap';
import { Formik } from 'formik';
import FormActionButtons from './FormActionButtons'
import _ from 'lodash'
import DatePicker, { registerLocale } from "react-datepicker"
import enGB from 'date-fns/locale/en-GB';
import * as constants from '../../constants'
import Loading from '../Loading'
import { dateFormatter, dateTimeFormatter } from '../grid/ColumnHelpers'
import HTMLEditor from './HTMLEditor'
import { fileRenderer, FileUploaderField, isEmptyValue } from './FormHelpers'
import { SingleFileUploaderField, viewSingleFile } from './SingleFileUploader'
import TypeaheadField from './TypeaheadField'
import * as utils from '../../utils'
import { LargeFileUploaderField } from "./LargeFileUploader";
import DOMPurify from 'dompurify';

registerLocale('en-GB', enGB);

const createValidationFun = (formFieldCfgs, warnings) => (values) => {
    const validationPropName = warnings ? 'warnings' : 'validation'
    let errors = {};
    formFieldCfgs.forEach(cfg => {
        const cleanValue = DOMPurify.sanitize(values[cfg.name]).replace(/&lt;/g, '<').replace(/&gt;/g, '>')
        if (!warnings && cfg.required && isEmptyValue(values[cfg.name])) {
            errors[cfg.name] = 'Obligāts lauks'
        } else if (!warnings && typeof values[cfg.name] === 'string' && cleanValue.length !== values[cfg.name].length) {
            errors[cfg.name] = `Neatļauti/neaizvērti tagi izmantoti lauka ${cfg.name} tekstā`
        } else if (cfg[validationPropName]) {
            const error = cfg[validationPropName](values)
            if (error) {
                if (typeof error === 'object') {
                    errors = _.merge(errors, error)
                } else {
                    errors[cfg.name] = error
                }
            }
        }
        if (cfg.multiFieldCfgs && !_.isEmpty(values[cfg.name])) {
            const rows = values[cfg.name]
            cfg.multiFieldCfgs.forEach(mcfg => {
                if (!warnings && mcfg.required) {
                    rows.forEach((r, i) => {
                        if (isEmptyValue(r[mcfg.name])) {
                            if (!errors[cfg.name]) errors[cfg.name] = []
                            if (!errors[cfg.name][i]) errors[cfg.name][i] = {}
                            errors[cfg.name][i][mcfg.name] = 'Obligāts lauks'
                        }
                    })
                }
                if (mcfg[validationPropName]) {
                    rows.forEach((r, i) => {
                        const error = mcfg[validationPropName](r, values)
                        if (error) {
                            if (!errors[cfg.name]) errors[cfg.name] = []
                            if (!errors[cfg.name][i]) errors[cfg.name][i] = {}
                            errors[cfg.name][i][mcfg.name] = error
                        }
                    })
                }
            })
        }
    })
    return errors;
}

const fromMetadataToFormCfg = (metadata) => {
    const { name, label, required, type: metaType, length, insertable, updatable } = metadata

    //"isComplexType": false,
    //"enum": null,
    //"isCollection": false,
    //"refViewName": null,
    let type
    let renderClassName
    switch (metaType) {
        case 'string':
            if (length && length > 500) {
                type = "textarea"
                renderClassName = "italic display-linebreak"
            } else {
                type = "text"
            }
            break
        case 'long':
        case 'int':
        case 'decimal':
            type = "number"
            break
        case 'boolean':
            type = "checkbox"
            break
        case 'date':
            type = "date"
            break
        case 'dateTime':
            type = "time"
            break
        default:
            type = "text"
    }
    if (name === 'faili') {
        type = "files"
    }
    return {
        name,
        label,
        required,
        type,
        renderClassName,
        editable: insertable || updatable
    }
}

const DatePickerPortal = ({ children }) => ReactDOM.createPortal(children, window.document.body)

export class DateInputField extends Component {

    onChange = (date, e) => {
        const { setFieldValue, fieldName } = this.props
        if (e && e.stopPropagation) {
            e.stopPropagation();
        }
        setFieldValue(fieldName, date)
    }

    render() {
        const { currentVal, fieldName, autoFocus, errorForField, isTouchedField, extraFieldProps } = this.props
        const dateFormat = extraFieldProps.showTimeSelect ? constants.DATE_TIME_FORMAT : constants.DATE_FORMAT
        return <DatePicker
            id={fieldName}
            name={fieldName}
            autoFocus={autoFocus === true ? autoFocus : false}
            selected={currentVal}
            onChange={this.onChange}
            invalid={!!errorForField && !!isTouchedField}
            title={errorForField || ''}
            isClearable={true}
            className="form-control"
            dateFormat={dateFormat}
            locale="en-GB"
            showMonthDropdown
            showYearDropdown
            autoComplete='off'
            popperContainer={DatePickerPortal}
            {...extraFieldProps}
        />
    }
}

const disableArrows = (e) => {
    if (e.which === 38 || e.which === 40) {
        e.preventDefault()
    }
}

const disableWheel = (e) => {
    e.target.blur()
}

export class InputField extends Component {

    shouldComponentUpdate(nextProps, nextState) {
        const { currentVal, fieldType, errorForField, isTouchedField, extraFieldProps, fieldName } = this.props
        return (currentVal !== nextProps.currentVal
            || fieldType !== nextProps.fieldType
            || fieldName !== nextProps.fieldName
            || errorForField !== nextProps.errorForField
            || isTouchedField !== nextProps.isTouchedField
            || !_.isEqual(extraFieldProps, nextProps.extraFieldProps))
    }

    render() {
        const { fieldName, fieldType, autoFocus, errorForField, isTouchedField, extraFieldProps, handleBlur, handleChange } = this.props
        let { currentVal } = this.props
        const additionalProps = {}
        if (['text', 'textarea', 'number', 'html', undefined].includes(fieldType) && (currentVal == null || currentVal === undefined)) {
            currentVal = ''
        }
        if (fieldType === 'checkbox') {
            additionalProps.checked = currentVal === true
        }
        if (fieldType === 'textarea') {
            additionalProps.rows = 3
        }
        if (fieldType === 'number') {
            additionalProps.onKeyDown = disableArrows
            additionalProps.onWheel = disableWheel
        }
        return <Input
            id={fieldName}
            type={fieldType || 'text'}
            name={fieldName}
            autoFocus={autoFocus === true ? autoFocus : false}
            onChange={handleChange}
            onBlur={handleBlur}
            value={currentVal}
            invalid={!!errorForField && !!isTouchedField}
            title={errorForField || ''}
            {...additionalProps}
            {...extraFieldProps}
        />
    }
}

const getFieldComponentFromType = (props) => {
    const { fieldType, fieldName, currentVal } = props
    if (props.customField) {
        const CustomField = props.customField
        return <CustomField {...props} />
    }
    switch (fieldType) {
        case 'files':
            return <FileUploaderField
                {...props}
            />
        case 'file':
            return <SingleFileUploaderField
                {...props}
            />
        case 'largefile':
            return <LargeFileUploaderField
                {...props}
            />
        case 'html':
            return <HTMLEditor
                {...props}
                value={props.currentVal ?? ''}
            />
        case 'autocomplete':
            return <TypeaheadField {...props} />
        case 'multicheckbox':
            if (!props.options) {
                throw new Error("options prop is required for multicheckbox")
            }
            return Object.keys(props.options).map(k => {
                const fieldProps = {
                    plainFieldName: fieldName,
                    fieldName: `${fieldName}.${k}`,
                    currentVal: currentVal[k],
                    dataObjVal: currentVal[k]
                }
                return <FormGroup check inline key={k} className="no-wrap">
                    <Label check>
                        <InputField {...props} {...fieldProps} fieldType="checkbox" /> {props.options[k].nos}
                    </Label>
                </FormGroup>
            })
        case 'radio':
            if (!props.options) {
                throw new Error("options prop is required for radio buttons")
            }
            return Object.keys(props.options).map(k => {
                const fieldProps = {
                    value: k,
                    checked: currentVal === k,
                    id: `${fieldName}_${k}`
                }
                return <FormGroup check inline key={k}>
                    <Label check>
                        <InputField {...props} extraFieldProps={{ ...props.extraFieldProps, ...fieldProps }} />{props.options[k].nos}
                    </Label>
                </FormGroup>
            })
        case 'date':
            return <DateInputField {...props} />
        default:
            return <InputField {...props} />
    }
}

const serializeVal = (value) => JSON.stringify(value === undefined ? null : value)

const renderMultiFieldSubField = ({ currentVal, fieldProps, currentRow, allProps = {} }) => fieldProps.renderer ?
    fieldProps.renderer(currentVal, currentRow, { ...allProps, ...fieldProps }) :
    transformFieldValueBasedOnFieldType({ fieldValue: currentVal, fieldType: fieldProps.fieldType, isHtmlValue: fieldProps.isHtmlValue })

const getMultiFieldCfgsWithMetadata = ({ multiFieldCfgs, metadataMultifieldCfgs }) => {
    if (!multiFieldCfgs) { return multiFieldCfgs }
    return multiFieldCfgs.map(f => {
        //try to get field cfgs from metadata
        const cfgFromMeta = metadataMultifieldCfgs ? metadataMultifieldCfgs.find(m => m.name === f.name) : {}
        const fieldProps = { ...cfgFromMeta, ...f }
        fieldProps.fieldType = fieldProps.type
        return fieldProps
    })
}

const renderMultiFieldTableHeader = ({ multiFieldCfgsWithMetadata, emptyAtBegining = 0, emptyAtEnd = 0 }) => {
    const emptyAtBeginingArr = Array.from(Array(emptyAtBegining))
    const emptyAtEndArr = Array.from(Array(emptyAtEnd))
    return <thead>
        <tr>
            {emptyAtBeginingArr.map((e, i) => <th key={`b_${i}`}>&nbsp;</th>)}
            {multiFieldCfgsWithMetadata.map(field => <th key={field.name} dangerouslySetInnerHTML={{ __html: field.label }}></th>)}
            {emptyAtEndArr.map((e, i) => <th key={`e_${i}`}>&nbsp;</th>)}
        </tr>
    </thead>
}

const handleMultiFieldRowSelect = (ind, props, fieldValue) => () => {
    if (props.onSelectMultiFieldRow) {
        fieldValue.forEach((row, i) => {
            if (ind === i) {
                row.isRowSelected = !row.isRowSelected
            } else {
                row.isRowSelected = false
            }
        })
        props.setFieldValue(props.fieldName, fieldValue)
        // check, because select might be on delete button
        if (fieldValue[ind]) {
            props.onSelectMultiFieldRow(fieldValue[ind])
        }
    }
}

const rendererForMultifieldTableWithRowspans = (props) => {
    const { metadataMultifieldCfgs, multiFieldCfgs, fieldValue, multiFieldColsToGroup = 0 } = props
    const multiFieldCfgsWithMetadata = getMultiFieldCfgsWithMetadata({ multiFieldCfgs, metadataMultifieldCfgs })

    const rowsWithSpanData = fieldValue.reduce((newRows, row, rowInd) => {
        // stop group columns if one cell differs
        let keepRowSpan = true
        newRows.push(multiFieldCfgsWithMetadata.map((field, fieldInd) => {
            let rowspan = 1
            // check whether the field value is the same as the field value from prev row, then rowspan
            if (keepRowSpan && fieldInd < multiFieldColsToGroup && rowInd > 0 &&
                serializeVal(row[field.name]) === serializeVal(fieldValue[rowInd - 1][field.name])) {
                // fields are the same
                rowspan = 0
                // increase rowspan for prev non-zero rowspan
                let prevRowspan = 0
                let prevInd = rowInd
                while (prevRowspan === 0) {
                    prevRowspan = newRows[--prevInd][fieldInd].rowspan
                }
                newRows[prevInd][fieldInd].rowspan = ++prevRowspan
            } else {
                keepRowSpan = false
            }
            return { currentVal: row[field.name], fieldProps: field, currentRow: row, rowspan }
        })
        )
        return newRows
    }, [])

    return <div className="multifield-table-container">
        <table className="multifield-table table table-striped">
            {renderMultiFieldTableHeader({ multiFieldCfgsWithMetadata })}
            <tbody>
                {
                    rowsWithSpanData.map((row, i) =>
                        <tr key={i} onClick={handleMultiFieldRowSelect(i, props, fieldValue)} className={(row.length > 0 && row[0].currentRow && row[0].currentRow.isRowSelected) ? 'row-selected' : ''}>
                            {row.map((cell, cellInd) => {
                                const { currentVal, fieldProps, currentRow, rowspan } = cell
                                const { cellClassName } = fieldProps
                                let clsName = () => {
                                    return cellClassName || ''
                                }
                                if (typeof cellClassName === 'function') {
                                    clsName = cellClassName
                                }
                                if (rowspan > 0) {
                                    return <td rowSpan={rowspan} key={cellInd} className={clsName(currentVal, currentRow)}>
                                        {renderMultiFieldSubField({ currentVal, fieldProps, currentRow, allProps: props })}
                                    </td>
                                } else {
                                    return null
                                }
                            }
                            )}
                        </tr>
                    )
                }
            </tbody>
        </table>
    </div>
}

const renderMultifieldField = ({ props, f, i, fieldName, currentRow, errorForField, isTouchedField }) => {
    //try to get field cfgs from metadata
    let fieldProps = {
        ...props,
        renderer: undefined,
        extraFieldProps: undefined,
        customField: undefined,
        label: undefined,
        ...f,
        multiFieldArrInd: i,
        plainFieldName: fieldName,
        fieldName: `${fieldName}[${i}].${f.name}`,
        currentVal: currentRow[f.name],
        dataObjVal: currentRow[f.name],
        errorForField: errorForField && errorForField[i] && errorForField[i][f.name] ? errorForField[i][f.name] : null,
        isTouchedField: isTouchedField && isTouchedField[i] && isTouchedField[i][f.name] ? isTouchedField[i][f.name] : null,
        currentValRow: currentRow,
        fieldNameRow: `${fieldName}[${i}]`
    }
    if (f.overridePropsFunc) {
        fieldProps = f.overridePropsFunc(fieldProps)
    }
    // placeholder as label
    if (fieldProps.label) {
        if (!fieldProps.extraFieldProps) {
            fieldProps.extraFieldProps = {}
        }
        if (!fieldProps.extraFieldProps.placeholder) {
            fieldProps.extraFieldProps.placeholder = fieldProps.label
        }
    }
    let tdClassName = fieldProps.cellClassName || ''
    if (fieldProps.errorForField) {
        tdClassName = tdClassName + ' red-back'
    }
    return <td key={f.name} className={tdClassName}>
        {currentRow.inEditMode && fieldProps.editable !== false ?
            <FormGroup>{
                fieldProps.fieldType === 'files' ?
                    <MultiField {...{ ...fieldProps, currentVal: fieldProps.currentVal, currentRow, renderer: fileRenderer, multiFieldCfgs: undefined, metadataMultifieldCfgs: undefined }} /> :
                    getFieldComponentFromType(fieldProps)
            }</FormGroup> :
            renderMultiFieldSubField({ currentVal: fieldProps.currentVal, fieldProps, currentRow })
        }
        {typeof fieldProps.errorForField === 'string' || fieldProps.errorForField instanceof String ? <div className="notes">{fieldProps.errorForField}</div> : null}
    </td>
}

// unique keys for added new items
let keyCounter = 0;
// renders multifield (either with multiFieldCfgs or with multifield prop set to true)
const MultiField = (props) => {

    const { currentVal, fieldName, setFieldValue, errorForField, isTouchedField, multiFieldCfgs, metadataMultifieldCfgs } = props

    const handleDeleteItem = (ind) => () => {
        currentVal.splice(ind, 1)
        setFieldValue(fieldName, currentVal)
    }

    const tableContainerEl = useRef(null)

    const handleAddItem = () => {
        let newRow = { key: ++keyCounter, inEditMode: true }
        if (props.createNewRow) {
            newRow = { ...newRow, ...props.createNewRow(props) }
        }
        currentVal.push(newRow)
        setFieldValue(fieldName, currentVal)
        handleMultiFieldRowSelect(currentVal.length - 1, props, currentVal)()
        setTimeout(() => {
            tableContainerEl.current.scrollTop = 10000
        }, 300);
    }
    const handleEditItem = (ind) => () => {
        currentVal[ind].inEditMode = !currentVal[ind].inEditMode
        setFieldValue(fieldName, currentVal)
    }

    const multiFieldCfgsWithMetadata = getMultiFieldCfgsWithMetadata({ multiFieldCfgs, metadataMultifieldCfgs })

    const tableClassnames = multiFieldCfgsWithMetadata ? "multifield-table table table-striped" : ""
    const containerClassnames = multiFieldCfgsWithMetadata ? "multifield-table-container" : ""

    return <>

        {props.errorForField ?
            <div className="danger">Dati aizpildīti nekorekti</div>
            : null}

        <div ref={tableContainerEl} className={containerClassnames}>
            <table className={tableClassnames}>

                {multiFieldCfgsWithMetadata ? renderMultiFieldTableHeader({ multiFieldCfgsWithMetadata, emptyAtBegining: props.extraFieldProps.canEdit === false ? 0 : 1, emptyAtEnd: props.extraFieldProps.canRemove === false ? 0 : 1 }) : null}

                <tbody>

                    {currentVal.map((currentRow, i) => {
                        if (currentRow.hiddenRow === true) {
                            return null
                        }
                        return <tr key={`id_${currentRow.id}_key_${currentRow.key}`} onClick={handleMultiFieldRowSelect(i, props, currentVal)} className={currentRow.isRowSelected ? 'row-selected' : ''}>
                            {multiFieldCfgsWithMetadata ? <>
                                {props.extraFieldProps.canEdit === false ? null :
                                    <td className="td-button">
                                        <Button color="success" disabled={!currentRow.id || (props.extraFieldProps.canEditRow && props.extraFieldProps.canEditRow(currentRow) === false)} outline size="sm" onClick={handleEditItem(i)}><i className="fa fa-pencil"></i></Button>
                                    </td>}
                                {multiFieldCfgsWithMetadata.map(f => renderMultifieldField({ props, f, i, fieldName, currentRow, errorForField, isTouchedField }))}
                            </> : <td className="flex-child">
                                {getFieldComponentFromType({
                                    ...props,
                                    multiFieldArrInd: i,
                                    plainFieldName: fieldName,
                                    fieldName: `${fieldName}[${i}]`,
                                    currentVal: currentRow,
                                    dataObjVal: currentRow,
                                    errorForField: errorForField && errorForField[i] ? errorForField[i] : null,
                                    isTouchedField: isTouchedField && isTouchedField[i] ? isTouchedField[i] : null
                                })}
                            </td>
                            }
                            {props.extraFieldProps.canRemove === false ? null : <td className="td-button">
                                <Button color="danger" disabled={props.extraFieldProps.canRemoveRow && props.extraFieldProps.canRemoveRow(currentRow) === false} outline size="sm" onClick={handleDeleteItem(i)}><i className="fa fa-trash-o"></i></Button>
                            </td>
                            }
                        </tr>
                    })
                    }
                </tbody>
            </table>
        </div>
        {props.extraFieldProps.canAdd === false ? null : <div>
            <Button color="primary" outline size="sm" onClick={handleAddItem}><i className="fa fa-plus-circle"></i>&nbsp;&nbsp;{props.extraFieldProps.addButtonText || 'Pievienot'}</Button>
        </div>
        }

    </>
}

const transformFieldValueBasedOnFieldType = ({ fieldValue, fieldType, isHtmlValue }) => {
    if (fieldType === 'checkbox') {
        return fieldValue === true ? 'Jā' : 'Nē'
    } else if (fieldType === 'date') {
        return dateFormatter(fieldValue)
    } else if (fieldType === 'time') {
        return dateTimeFormatter(fieldValue)
    } else if (fieldType === 'html' || isHtmlValue) {
        return <span dangerouslySetInnerHTML={{ __html: fieldValue }} />
    } else if (fieldType === 'files') {
        return fileRenderer(fieldValue)
    }
    return fieldValue
}

const FormField = (inProps) => {
    let props = inProps
    if (inProps.overridePropsFunc) {
        props = inProps.overridePropsFunc(inProps)
    }

    const { isHtmlValue, sizeForm, sizeLabel, formErrorFields, formTouchedFields, fieldName, editable, label, fieldType, dataObj,
        extraFieldProps, renderer: rendererProp, renderClassName, fieldEditable, multiField, multiFieldCfgs, hiddenFunc, formRowProps, fieldNotes, uploadSize, toolTip } = props

    const errorForField = props.errorForField || formErrorFields[fieldName]
    const isTouchedField = formTouchedFields[fieldName]

    const renderClass = renderClassName ? renderClassName + ' form-col' : 'form-col'

    // check is field hidden
    if (hiddenFunc && hiddenFunc(props)) {
        return null
    }

    // show field in edit mode
    const editMode = editable && fieldEditable !== false

    // process fieldvalue for output
    let fieldValue
    let renderer = rendererProp
    if (!editMode && !renderer) {
        fieldValue = transformFieldValueBasedOnFieldType({ fieldValue: _.get(dataObj, fieldName), fieldType, isHtmlValue })
    }
    if (!renderer && fieldType === 'files') {
        renderer = fileRenderer
    }
    if (!renderer && fieldType === 'file') {
        renderer = viewSingleFile
    }
    if (!renderer && fieldType === 'largefile') {
        renderer = viewSingleFile
    }

    let extraProps = {}
    if (editMode) {
        if (extraFieldProps) {
            if (typeof extraFieldProps === "function") {
                extraProps = extraFieldProps(props)
            } else {
                extraProps = { ...extraFieldProps }
            }
        }
    }

    let errorClassName = 'formlabel-col'
    if (editMode && errorForField) {
        errorClassName = errorClassName + ' danger'
    }
    const sizeLbl = sizeLabel ? sizeLabel : 4
    const sizeFrm = sizeForm ? sizeForm : 8

    const toolTipFieldName = 'Tooltip-' + fieldName.replace('.', '-');

    return (
        <FormGroup row {...formRowProps}>
            {label || label === '' ?
                (
                    <Col xs={12} md={sizeLbl} className={errorClassName}>
                        <Label for={fieldName} className="field-label">{label}
                            {toolTip && 
                            (<>
                                <span id={toolTipFieldName} className="ml-2 fa fa-question-circle" style={{ color: '#31669c' }}/>
                                <UncontrolledTooltip placement="right" target={toolTipFieldName}>
                                    {toolTip}
                                </UncontrolledTooltip>
                            </>)}
                        </Label>
                    </Col>
                )
                : null
            }
            {editMode ?
                (
                    <Col xs={12} md={sizeFrm} className="form-col">
                        {multiField === true || multiFieldCfgs || fieldType === 'files' ?
                            <MultiField {...{ ...props, extraFieldProps: extraProps, errorForField, isTouchedField }} /> :
                            getFieldComponentFromType({ ...props, extraFieldProps: extraProps, errorForField, isTouchedField, uploadSize })}
                        {fieldNotes ? <div className="notes">{fieldNotes}</div> : null}
                        {typeof errorForField === 'string' || errorForField instanceof String ? <div className="notes danger">{errorForField}</div> : null}
                    </Col>
                )
                :
                (
                    <Col xs={12} md={sizeFrm} className={renderClass}>
                        {renderer ? renderer(_.get(dataObj, fieldName), dataObj, props) :
                            multiFieldCfgs ? rendererForMultifieldTableWithRowspans({ ...props, fieldValue: _.get(dataObj, fieldName) }) : fieldValue}
                        {fieldNotes && editable ? <div className="notes">{fieldNotes}</div> : null}
                    </Col>
                )
            }
        </FormGroup>
    )
}

class Form extends Component {

    constructor(props) {
        super(props)
        this.formKey = 1
        this.lastEditDataObj = null
        this.lastEditedFieldName = ''
        this.state = {
            warnings: {}
        }
        this.maybeNullFields = []
    }

    //reinititalize formik form completely when dataObj changes
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (!this.lastEditDataObj || this.lastEditDataObj !== nextProps.dataObj) {
            this.formKey++
            this.lastEditDataObj = nextProps.dataObj
            this.lastEditedFieldName = ''
            this.maybeNullFields = []
        }
    }

    // wrap formik setFieldValue to store last edited field name
    getWrapperSetFieldValue = (setFieldValueFunc) => (fieldName, value, options) => {
        this.lastEditedFieldName = fieldName
        console.log(`lastEditedFieldName = ${fieldName}`)
        return setFieldValueFunc(fieldName, value, options)
    }

    // wrap also handleChange
    getWrappedHandleChange = (handleChangeFunc) => (e) => {
        this.lastEditedFieldName = e.target.name
        // we store fields which might be null
        if (e.target.value === '' && e.target.type === 'number') {
            this.maybeNullFields.push(e.target.name)
        }
        return handleChangeFunc(e)
    }

    validateWarnings = (values) => {
        if (this.warningsFunc) {
            const warnings = this.warningsFunc(values)
            this.setState({ warnings })
        }
    }

    onSubmit = (...args) => {
        const values = args[0]

        //set null values to all number fields with '' value
        this.maybeNullFields.forEach(f => {
            const value = _.get(values, f)
            if (value === '') {
                _.set(values, f, null)
            }
        })

        this.props.onSubmit(...args)
    }

    render() {

        const { editable, submiting, dataObj, formFieldCfgs,
            onReset, onNew, onStartEdit, onDelete, formFieldWrapperCfgs, formWrapperComp,
            sizeForm, sizeLabel, metadata, metadataFormCfg, uploadSize } = this.props

        // loading while we have no atradne
        if (!dataObj || !metadata.loaded || !uploadSize.loaded) {
            return (<Loading />)
        }

        //transform initial values
        const initialValues = _.cloneDeep(dataObj)
        const createFields = ({ errors, touched, setFieldValue, setFieldTouched, values,
            handleBlur, handleChange, fieldWrapper }) => formFieldCfgs.filter(cfg => !fieldWrapper || cfg.fieldWrapper === fieldWrapper).map(fcfg => {
                // get default config from metadata
                let cfg = fcfg
                if (metadataFormCfg) {
                    const metadataField = metadata[metadataFormCfg].fields.find(f => f.name === fcfg.name)
                    if (metadataField) {
                        const metadataCfg = fromMetadataToFormCfg(metadataField)
                        cfg = { ...metadataCfg, ...fcfg }
                        //multi field default configs from metadata
                        if (fcfg.multiFieldCfgs && metadataField.type && metadata[metadataField.type]) {
                            cfg.metadataMultifieldCfgs = metadata[metadataField.type].fields.map(f => fromMetadataToFormCfg(f))
                        }
                    }
                }
                // create form field
                return <FormField
                    key={cfg.name}
                    fieldName={cfg.name}
                    editable={editable}
                    label={cfg.label}
                    autoFocus={cfg.autoFocus}
                    formErrorFields={errors}
                    formTouchedFields={touched}
                    fieldType={cfg.type}
                    dataObj={dataObj}
                    autocompleteCfg={cfg.autocompleteCfg}
                    setFieldValue={this.getWrapperSetFieldValue(setFieldValue)}
                    setFieldTouched={setFieldTouched}
                    extraFieldProps={cfg.extraFieldProps}
                    currentValues={values}
                    currentValRow={values}
                    renderer={cfg.renderer}
                    handleBlur={handleBlur}
                    handleChange={this.getWrappedHandleChange(handleChange)}
                    renderClassName={cfg.renderClassName}
                    customField={cfg.customField}
                    options={cfg.options}
                    fieldEditable={cfg.editable}
                    multiField={cfg.multiField}
                    multiFieldCfgs={cfg.multiFieldCfgs}
                    hiddenFunc={cfg.hiddenFunc}
                    sizeLabel={cfg.sizeLabel || sizeLabel}
                    sizeForm={cfg.sizeForm || sizeForm}
                    tableFields={cfg.tableFields}
                    formRowProps={cfg.formRowProps}
                    overridePropsFunc={cfg.overridePropsFunc}
                    fieldNotes={cfg.fieldNotes}
                    metadataMultifieldCfgs={cfg.metadataMultifieldCfgs}
                    multiFieldColsToGroup={cfg.multiFieldColsToGroup}
                    isHtmlValue={cfg.isHtmlValue}
                    currentVal={_.get(values, cfg.name)}
                    dataObjVal={_.get(dataObj, cfg.name)}
                    lastEditedFieldName={this.lastEditedFieldName}
                    createNewRow={cfg.createNewRow}
                    onSelectMultiFieldRow={cfg.onSelectMultiFieldRow}
                    uploadSize={uploadSize}
                    toolTip={cfg.toolTip}
                />
            })

        const createFieldWrappers = ({ errors, touched, setFieldValue, setFieldTouched, values,
            handleBlur, handleChange }) => formFieldWrapperCfgs
                // filter out empty field wrappers
                .filter(cfg => formFieldCfgs.filter(fc => fc.fieldWrapper === cfg.id).length > 0)
                // filter out hidden field wrappers
                .filter(cfg => !cfg.hiddenFunc || !cfg.hiddenFunc(this.props))
                .map(cfg => {
                    const Comp = cfg.comp
                    //create form field
                    return <Comp
                        key={cfg.id}
                        {...cfg.compProps}
                        currentValues={values}>
                        {createFields({
                            errors, touched, setFieldValue, setFieldTouched,
                            values, handleBlur, handleChange, fieldWrapper: cfg.id
                        })}
                    </Comp>
                })

        if (!this.validationFunc && formFieldCfgs) {
            this.validationFunc = createValidationFun(formFieldCfgs)
        }

        if (!this.warningsFunc && formFieldCfgs) {
            this.warningsFunc = createValidationFun(formFieldCfgs, true)
        }

        const FormWrapper = formWrapperComp ? formWrapperComp : React.Fragment
        const warningArray = utils.getValuesFromNestedObject(this.state.warnings)
        return (
            <Formik
                key={this.formKey}
                initialValues={initialValues}
                validate={(values) => { this.validateWarnings(values); return this.validationFunc(values) }}
                onSubmit={this.onSubmit}
                onReset={() => { this.formKey++; onReset(); }}
                render={({ handleSubmit, handleReset, setFieldTouched, errors, touched, setFieldValue, values, handleBlur, handleChange }) => (<>

                    <FormActionButtons
                        editable={editable}
                        submiting={submiting}
                        handleSubmit={handleSubmit}
                        handleReset={handleReset}
                        handleNew={onNew}
                        handleStartEdit={onStartEdit}
                        handleDelete={!dataObj.id ? null : onDelete}
                        isValid={_.isEmpty(errors)}
                        extraElements={this.props.extraElements}
                        btnTexts={this.props.btnTexts}
                        tiesibasLabot={this.props.tiesibasLabot}
                        disabledEditTip={this.props.disabledEditTip}
                    />

                    <RSForm className={this.props.className}>
                        <FormWrapper>
                            {warningArray.length > 0 ? warningArray.map((w, i) =>
                                <div key={i} className="warning padding-5"><i className="fa fa-exclamation-triangle" aria-hidden="true"></i> {w}</div>
                            ) : null}
                            {formFieldWrapperCfgs && formFieldWrapperCfgs.length > 0 ?
                                createFieldWrappers({ errors, touched, setFieldValue, setFieldTouched, values, handleBlur, handleChange })
                                :
                                createFields({ errors, touched, setFieldValue, setFieldTouched, values, handleBlur, handleChange })
                            }
                        </FormWrapper>
                    </RSForm>

                </>
                )}
            />
        )
    }

}

const mapStateToProps = ({ metadata, uploadSize }) => ({ metadata, uploadSize })
export default connect(mapStateToProps)(Form)
